<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action" v-if="selectedVariant && backUpVariant && JSON.stringify(selectedVariant) !== JSON.stringify(backUpVariant)">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Өөрчлөлт хадгалах</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" :disabled="isDisabled" @click="onPressSaveNewVariant" v-if="isNewVariant">Үүсгэх</el-button>
          <el-button type="success" :disabled="isDisabled" v-else @click="onPressSave('selectedVariant', 'variantRules')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="20" :offset="2">
        <custom-header :title="selectedVariant ? isNewVariant ? 'Шинэ хувилбар' : selectedVariant.variant_name : ''"/>
        <el-form label-position="top" :rules="variantRules" :model="selectedVariant" ref="selectedVariant">
          <el-row :gutter="20">
            <el-col :span="8">
              <!-- product info section -->
              <div class="panel" v-if="productData">
                  <div class="panel-item">
                    <div class="panel-content">
                      <el-row :gutter="10" type="flex" align="middle">
                        <el-col :span="8">
                          <!-- <div class="image-holder">
                            <img :src="productData.image[0]">
                          </div> -->
                          <el-image
                            class="image-holder"
                            :src="Array.isArray(productData.image) ? productData.image[0] : productData.image"
                            fit="contain"></el-image>
                        </el-col>
                        <el-col :span="16">
                          <div class="mb5">
                            <span class="mr5 text-color-primary">{{productData.name_mon}}</span>
                            <el-tag :type="productData.status === 'active' ? 'success' : 'error'" style="line-height: 24px; height: 24px">{{productData.status === 'active' ? 'Идэвхитэй' : 'Идэвхигүй'}}</el-tag>
                          </div>
                          <div class="mb5"  v-if="productData.variants.length != 0">
                            <span class="text-color-secondary">
                              {{ productData.variants.length }} сонголт
                            </span>
                          </div>
                          <div>
                            <el-button class="text-primary" type="text" @click="cellClick(productData.productId)">Бүтээгдэхүүнрүү буцах</el-button>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
              </div>
              <!-- Variants section -->
              <div class="panel" v-if="productData && productData.variants">
                <div class="panel-item">
                  <header>Сонголтууд</header>
                </div>
                <div class="variant-list">
                  <div class="variant-item" v-for="(variant, index) in productData.variants" :key="index" :class="selectedVariant.variant_id === variant.variant_id ? 'active' : ''">
                    <el-row :gutter="10" type="flex" align="middle" @click.native="onPressVariant(variant)">
                      <el-col :span="6">
                        <el-image
                          v-if="variant && variant.variant_image && Array.isArray(variant.variant_image) && variant.variant_image.length > 0"
                          class="image-holder"
                          :src="variant && variant.variant_image && Array.isArray(variant.variant_image) && variant.variant_image.length > 0 ? variant.variant_image[0] :  variant.variant_image"
                          fit="contain"></el-image>
                      </el-col>
                      <el-col :span="14">
                        <span>{{variant.variant_name}}</span>
                      </el-col>
                      <el-col :span="4">
                        <span><strong>{{variant.variant_sort}}</strong></span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
               <!-- view history -->
                <div class="panel-item pb20">
                  <el-form-item>
                    <el-button v-if="historyTotal != '0'" type="success" class="block" @click="dialogVisibleHistory = true">Түүх үзэx</el-button>
                  </el-form-item>
                  </div>
              <el-button type="primary" @click="onPressCancelNew" v-if="isNewVariant">Болих</el-button>
              <el-button type="primary" v-show="selectedVariant.option.length >= 1" @click="onPressNewVariant" v-else>Хувилбар үүсгэх</el-button>
            </el-col>
            <el-col :span="16">
              <!-- Options section -->
              <div class="panel" v-if="selectedVariant">
                  <div class="panel-item">
                    <header>Төрөл</header>
                    <div class="panel-content">
                      <el-form-item v-for="(item, index) in selectedVariant.option" :key="index" :label="item.name">
                        <el-input v-if="item.name !== 'Өнгө'" v-model="item.value" key="index"></el-input>
                        <el-select filterable v-else v-model="item.value" placeholder="Өнгө сонгох">
                          <el-option
                            v-for="(color, index) in colorList"
                            :key="index"
                            :label="color.name_mon"
                            :value="color.name_mon">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="variant_image" label="Зураг /төрөл/">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :file-list="tempImages"
                          :auto-upload="false"
                          :on-preview="handlePictureCardPreview"
                          :on-remove="handleRemove"
                          :on-change="getFile">
                          <span>1020x1020</span>
                        </el-upload>
                        <loader :active="isUploading" />
                      </el-form-item>
                    </div>
                  </div>
              </div>
              <!-- Status section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <header>Төлөв</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item prop="variant_status" label="Төлөв">
                        <el-select v-model="selectedVariant.variant_status" class="block" v-if="productData && productData.status">
                          <el-option
                            v-for="(item, index) in statusOption"
                            :key="index"
                            :label="productData.status === 'price_inactive' ? 'Үнэ батлаагүй' : selectedVariant.variant_status === 'price_inactive' ? 'Үнэ батлаагүй' : item.label"
                            :value="item.value"
                            :disabled="productData.status === 'price_inactive' ? true : selectedVariant.variant_status === 'price_inactive' ? true : false">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="variant_sort" label="Эрэмбэлэх">
                        <el-input placeholder="1 ..." v-model="selectedVariant.variant_sort"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Pricing section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Зарах үнэ" prop="variant_sellPrice">
                        <el-input v-model="selectedVariant.variant_sellPrice" type="number" placeholder="0₮" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Хямдарсан үнэ">
                        <el-input v-model="selectedVariant.variant_sale_price" type="number" placeholder="0₮" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                      <!-- <el-form-item label="Хямдралтай эсэх">
                        <el-checkbox  v-model="isDiscount">
                        </el-checkbox>
                      </el-form-item> -->
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Эхлэх өдөр">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="selectedVariant.start_date_sale" type="datetime" placeholder="Эхлэх огноо">
                        </el-date-picker>
                      </el-form-item>
                      <!-- <el-form-item label="Хямдралын хугацаа оруулах">
                        <el-checkbox v-model="isDiscountDate" type="datetime" placeholder="Эхлэх огноо">
                        </el-checkbox>
                      </el-form-item> -->
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8" :offset="8">
                    </el-col>
                    <el-col :span="8">
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8" :offset="8">
                      <el-form-item label="Хямдралтай татах үнэ">
                        <el-input v-model="selectedVariant.variant_get_sale_price" type="number" placeholder="0₮" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Дуусах өдөр">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="selectedVariant.end_date_sale" type="datetime" placeholder="Дуусах огноо">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Татах үнэ" :inline-message="true" :show-message="true" prop="variant_getPrice">
                        <el-input v-model="selectedVariant.variant_getPrice" type="number" placeholder="0₮" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                        <el-col :span="8">
                      <el-form-item label="Зөрүү">
                          <el-input placeholder="0%" v-model="difference" disabled>
                            <template slot="append">₮</template>
                          </el-input>
                      </el-form-item>
                    </el-col>
                        <el-col :span="8">
                      <el-form-item label="Ашиг">
                          <el-input placeholder="0%" v-model="percent" disabled>
                            <template slot="append">%</template>
                          </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Group order section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Групп захиалга</header>
                </div>
                <div class="panel-item">
                  <strong>24</strong> цагийн дотор <strong>10</strong> хүн <strong>20%</strong> хямдралтай авах боломжтой
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel" v-if="selectedVariant">
                <div class="panel-item">
                  <el-row :gutter="0" type="flex" justify="space-between">
                    <header>{{`Агуулах (Нийт үлэдэгдэл: ${selectedVariant.avalabileCount})`}}</header>
                    <el-button type="success" size="mini" icon="el-icon-plus" class="ml10" plain @click="addWarehouse">Агуулах нэмэх</el-button>
                  </el-row>
                  <el-row :gutter="20" v-for="(warehouse, warehouseIndex) in selectedVariant.warehouse" :key="warehouseIndex">
                    <el-col :span="11">
                      <el-form-item :label="`Агуулах ${warehouseIndex + 1}`">
                        <el-select v-model="warehouse.warehouse_id" placeholder="Агуулах">
                          <el-option
                            v-for="(item, warehouseIndex1) in warehouses"
                            :key="warehouseIndex1"
                            :label="item.name_mon"
                            :value="item.warehouse_id"
                            v-bind:disabled="selectedVariant.warehouse.some(el => el.warehouse_id === item.warehouse_id)">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item label="Тоо ширхэг">
                        <el-input type="number" v-model="warehouse.count"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2">
                      <el-button class="mt25" type="info" icon="el-icon-minus" plain circle @click="deleteWarehouse(warehouseIndex)"></el-button>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Доод үлдэгдэл" prop="threshold_number">
                        <el-input type="number" v-model="selectedVariant.threshold_number" :min="0">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="SKU">
                        <el-input type="text" disabled :value="selectedVariant.variant_sku">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="!isNewVariant">
                    <el-col :span="12">
                      <el-form-item label="Barcode">
                        <el-input type="text" v-model="selectedVariant.variant_barcode">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="7">
                      <el-form-item label="Бүтээгдэхүүний дуусах хугацаа">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="selectedVariant.variant_endDate"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" class="pt25">
                      <el-button type="success" :disabled="isDisabledButton('barCode')" circle icon="el-icon-check" @click="setVariantBarCodeAndExpiredDate(selectedVariant.variant_id)"></el-button>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-item">
                  <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Diamond код">
                            <el-input v-model="selectedVariant.diamond_code" placeholder="123456 ..."></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" class="mt20">
                        <el-form-item>
                            <el-checkbox v-model="selectedVariant.sell_check">Агуулахад байхгүй ч зарах</el-checkbox>
                        </el-form-item>
                      </el-col>
                  </el-row>
                </div>
                <!-- <div class="panel-item" v-if="currentWarehouse.length !== 0">
                  <el-table
                    class="mb20"
                    v-loading="isLoadWarehouse"
                    @cell-click="onClickWarehouse"
                    :data="warehouseList ? warehouseList : null">
                    <el-table-column
                      label="Агуулахын нэр"
                      style="flex:1"
                      prop="name_mon">
                    </el-table-column>
                    <el-table-column
                      label="Үлдэгдэл"
                      style="flex:1"
                      align="center">
                      <template slot-scope="scope">
                        <span :class="scope.row.count > 0 ? '' : 'text-danger'">{{scope.row.count}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Татан авалт"
                      style="flex:1"
                      align="center">
                      <template slot-scope="scope">
                        <el-popover
                          placement="bottom"
                          title="Татан авалт"
                          width="200"
                          trigger="click">
                          <div class="transfer-info">
                            <el-row :gutter="10" type="flex" align="middle" v-for="(transfer, index) in scope.row.transfers" :key="index">
                              <el-col :span="12" >
                                <router-link :to="{ name : 'editTransfer', params: { transfer_id: transfer.transfer_id }}" class="text-primary">#{{transfer.transfer_id}} - {{transfer.transferCount}}</router-link>
                              </el-col>
                            </el-row>
                          </div>
                          <span slot="reference" to="" class="text-primary cursor-pointer">{{scope.row.transferCount}}</span>
                        </el-popover>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div v-if="selectedIndex !== null && warehouseList[selectedIndex]">
                    <header>Байршил / <span style="color: red">{{warehouseList[selectedIndex].name_mon}}</span> /</header>
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="Тавиур">
                          <el-input type="text" v-model="warehouseList[selectedIndex].rack">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Давхар">
                          <el-input type="text" v-model="warehouseList[selectedIndex].shelf">
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Нүд">
                          <el-input type="text" v-model="warehouseList[selectedIndex].unit">
                          </el-input>
                        </el-form-item>
                      </el-col>
                       <el-col :span="6">
                        <el-form-item class="pt25">
                          <el-button type="success" circle icon="el-icon-check" :disabled="isDisabledButton('position', warehouseList[selectedIndex])" @click="setVariantRackPosition(warehouseList[selectedIndex], selectedVariant)"/>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <el-button v-if="changesData.length != 0" type="text" class="text-primary" @click="dialogTableVisible = true">Агуулга түүх харах</el-button>
                </div> -->
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
            <el-button type="success" :disabled="isDisabled" @click="onPressSaveNewVariant" v-if="isNewVariant">Үүсгэх</el-button>
            <el-button type="success" :disabled="isDisabled" @click="confirmVariantDialog = true" v-else>Шинэчлэх</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Update dialog -->
    <el-dialog
      title="Хувилбар шинэчлэх"
      :visible.sync="confirmVariantDialog"
      width="50%"
    >
      <div>
        <strong>{{selectedVariant.variant_name}}</strong> хувилбар шинэчлэхдээ шалтгаанаа заавал бичнэ үү?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmVariantDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="success" :disabled="isDisabled" @click="onPressSave('selectedVariant', 'variantRules')">
          Хадгалах
        </el-button>
      </span>
      <el-row :gutter="20">
        <el-col :span="24" class="mt20">
          <el-form :model="selectedVariant" :rules="variantRules" ref="selectedVariant">
            <el-form-item prop="desc" label="Шалтгаан">
              <el-input v-model="selectedVariant.desc" type="textarea" :rows="2" placeholder="Шинэчилж буй шалтгаанаа бичнэ үү ..."></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- Inventory history dialog -->
    <el-dialog title="Агуулга түүх" :visible.sync="dialogTableVisible" top="20px">
      <el-table :data="changesData">
        <el-table-column property="created_at" label="Он\Сар\Өдөр" width="150"></el-table-column>
        <el-table-column property="user_name" label="Өөрчлөгч" width="100"></el-table-column>
        <el-table-column property="warehouse_name" label="Агуулах" width="100"></el-table-column>
        <el-table-column label="Өөрчлөлт" width="100">
          <template slot-scope="scope">
            <span :class="(scope.row.old_count - scope.row.new_count) > 0 ? 'text-danger' : 'text-success'">{{((scope.row.old_count - scope.row.new_count) * -1) > 0 ? "+" + ((scope.row.old_count - scope.row.new_count) * -1) : ((scope.row.old_count - scope.row.new_count) * -1) }}</span>
          </template>
        </el-table-column>
        <el-table-column property="new_count" label="Боломжтой" width="100"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та устгах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Устгах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
    <el-dialog title="Түүх Дхарах" :visible.sync="dialogVisibleHistory" width="50%">
        <el-table :data="historyData"
        @cell-click="showHistoryDialog"
        style="width: 100%">
          <el-table-column
          prop="created_at" label="Date" width="180">
          </el-table-column>
          <el-table-column
          prop="user_name" label="Хэрэглэгч" width="240">
          </el-table-column>
          <el-table-column
          prop="type" label="Үйлдэл">
          </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exitHistory">Буцах</el-button>
      </span>
    </el-dialog>
    <!-- detail view History -->
    <loader :active="isLoading" is-full-screen />
    <el-dialog title="Түүх дэлгэрэнгүй харах" :visible.sync="historyDetail" width="70%">
      <el-table
      :data="historyDetailData"
      style="width: 100%">
      <el-table-column
        prop="variant_name" label="Хувилбар нэр">
      </el-table-column>
      <el-table-column
        prop="variant_getPrice" label="Татах үнэ">
      </el-table-column>
      <el-table-column
        prop="variant_sale_price" label="Хямдарсан үнэ">
      </el-table-column>
      <el-table-column
        prop="variant_sellPrice" label="Зарах үнэ">
      </el-table-column>
      <el-table-column
        prop="threshold_number" label="Доод үлдэгдэл">
      </el-table-column>
      <el-table-column
        prop="variant_barcode" label="Barcode">
      </el-table-column>
    </el-table>
    <el-row :gutter="20" class="mt10">
      <el-col align="middle">
        <el-button type="danger" @click="exitHistoryDetail">Хаах</el-button>
      </el-col>
    </el-row>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import helper from '../../../helpers/helper'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import Loader from '../../../components/common/loader'
import { getUserName } from '../../../utils/auth'
export default {
  name: 'StockDetail',
  components: {
    CustomHeader,
    Loader
  },

  data () {
    return {
      warehouses: [],
      // isDiscountDate: false,
      // isDiscount: false,
      colorList: [],
      historyTotal: '',
      selectedLogId: '',
      selectedLog: [],
      taxPaid: '',
      difference: '',
      percent: '',
      suppliers: [],
      historyDetailData: [],
      historyData: [],
      historyName: 'variant',
      dialogVisibleHistory: false,
      historyDetail: false,
      isDisabled: false,
      confirmVariantDialog: false,
      productData: null,
      isNewVariant: false,
      tempImages: [],
      selectedVariant: {
        variant_get_sale_price: 0,
        start_date_sale: null,
        end_date_sale: null,
        diamond_code: '',
        variant_sort: '',
        variant_endDate: '',
        variant_barcode: '',
        desc: '',
        created_at: '',
        variant_id: '',
        variant_getPrice: 0,
        variant_sellPrice: 0,
        variant_sale_price: 0,
        product_id: '',
        variant_name: '',
        variant_sku: '',
        variant_status: '',
        variant_image: '',
        threshold_number: '',
        sell_check: false,
        option: [],
        warehouse: [{
          count: 0,
          warehouse_id: ''
        }]
      },
      backUpVariant: {
        variant_getPrice: 0,
        variant_sellPrice: 0,
        variant_sale_price: 0,
        variant_status: '',
        variant_image: ''
      },
      changesData: [],
      statusOption: [],
      warehouseList: [],
      currentWarehouse: [],
      selectedWarehouse: null,
      selectedIndex: null,
      dialogTableVisible: false,
      gridData: null,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      isUploading: false,
      isLoading: false,
      dialogVisible: false,
      isLoadWarehouse: false,
      userName: null,
      search: '',

      variantRules: {
        variant_sort: [
          { required: true, message: 'Эрэмбэ заавал оруулна уу?', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: 'Шинэчилж буй шалтгаанаа оруулна уу?', trigger: 'blur' }
        ],
        variant_name: [
          { required: true, message: 'Хувилбарын нэрээ оруулна уу', trigger: 'blur' }
        ],
        variant_sellPrice: [
          { required: true, message: 'Зарах үнээ оруулна уу', trigger: 'blur' }
        ],
        variant_sale_price: [
          { required: true, message: 'Хямдарсан үнээ оруулна уу', trigger: 'blur' }
        ],
        variant_getPrice: [
          { required: true, message: 'Татах үнээ оруулна уу', trigger: 'blur' }
        ],
        threshold_number: [
          { required: true, message: 'Доод үлдэгдлээ оруулна уу', trigger: 'blur' }
        ],
        variant_status: [
          { required: true, message: 'Доод үлдэгдлээ оруулна уу', trigger: 'blur' }
        ],
        variant_image: [
          { required: true, message: 'Та зургаа оруулна уу?', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    if (this.$route.params.variant_id) {
      this.getVariants(this.$route.params.variant_id)
      this.getHistory(this.$route.params.variant_id)
      this.getStatusOptions()
      this.getColors()
      getUserName().then(email => {
        this.userName = email.split('@')[0]
      })
    }
  },
  methods: {
    addWarehouse () {
      this.selectedVariant.warehouse.unshift({
        count: 0,
        warehouse_id: ''
      })
    },
    deleteWarehouse (index) {
      this.$confirm('Та агуулах хасахдаа итгэлтэй байна уу?', 'Анхааруулга', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      }).then(async () => {
        if (this.selectedVariant.warehouse.length > 1) {
          this.selectedVariant.warehouse.splice(index, 1)
        } else {
          this.alertReporter('Анхааруулга', 'Уучлаарай та заавал агуулах сонгох ёстой. Агуулах байхгүй бол хэрэглэгчийн сайтаар нэвтэрч тухайн нийлүүлэгч дээр агуулах үүсгэнэ үү!', 'warning')
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Буцсан'
        })
      })
    },
    getWarehousesBySupplierId (id) {
      const body = {
        supplier_id: [id],
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
        } else {
          this.warehouses = []
        }
      })
    },
    cellClick (id) {
      this.$router.push({ name: 'updateProduct', params: { product_id: id } }).catch(() => {})
    },
    getColors () {
      const query = '?search_text=' + this.search + '&from=' + 0 + '&size=' + 1000
      services.getColors(query).then(data => {
        this.colorList = data.datas
      })
    },
    exitHistory () {
      this.dialogVisibleHistory = false
    },
    exitHistoryDetail () {
      this.historyDetail = false
    },
    getHistory (variantId) {
      const query = '?id=' + variantId + '&log_name=' + this.historyName
      services.getHistory(query).then(data => {
        this.historyData = data.data
        this.historyTotal = data.total
      })
    },
    getHistoryDetail (data) {
      services.getHistoryDetail(data._id).then(response => {
        if (response.status === 'success' && response.data) {
          this.historyDetailData = [response.data.old, response.data.new]
        }
      })
    },
    showHistoryDialog (data) {
      this.historyDetail = true
      this.getHistoryDetail(data)
    },
    setVariantBarCodeAndExpiredDate (id) {
      const body = {
        variant_id: id,
        expired_date: this.selectedVariant.variant_endDate,
        variant_barcode: this.selectedVariant.variant_barcode
      }
      services.setVariantBarCodeAndExpiredDate(body).then((response) => {
        if (response.status === 'success') {
          this.alertReporter(response.status === 'success' ? 'Амжилттай' : 'Уучлаарай', response.message, response.status)
        }
      })
    },

    setVariantRackPosition (warehouse, selectedVariant) {
      const body = {
        variant_id: selectedVariant.variant_id,
        warehouse_id: warehouse.warehouse_id,
        rack: warehouse.rack,
        shelf: warehouse.shelf,
        unit: warehouse.unit
      }
      services.setVariantRackPosition(body).then((response) => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, response.status)
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа гарлаа', response.message, response.status)
        }
      })
    },

    onPressSaveNewVariant () {
      var tempImages = []
      let tempVariantName = ''
      var variant = JSON.parse(JSON.stringify(this.selectedVariant))
      variant.product_id = this.backUpVariant.product_id
      variant.variant_get_sale_price = parseFloat(variant.variant_get_sale_price)
      variant.variant_getPrice = parseFloat(variant.variant_getPrice)
      variant.variant_sellPrice = parseFloat(variant.variant_sellPrice)
      variant.variant_sale_price = parseFloat(variant.variant_sale_price)
      variant.variant_sort = parseInt(variant.variant_sort)
      variant.warehouse.forEach(el => {
        el.count = parseInt(el.count)
      })
      variant.option.forEach((element) => {
        tempVariantName = tempVariantName + element.value
      })
      this.tempImages.forEach((element) => {
        tempImages.push(element.url)
      })
      variant.variant_name = tempVariantName
      variant.variant_image = tempImages
      this.isLoading = true
      this.isDisabled = true
      services.addVariant(variant).then((response) => {
        if (response.status === 'success') {
          this.isLoading = false
          this.isDisabled = false
          this.isNewVariant = false
          this.alertReporter(response.status === 'success' ? 'Амжилттай' : 'Уучлаарай', response.message, response.status)
          this.getVariants(this.$route.params.variant_id)
        } else {
          this.isLoading = false
          this.isDisabled = false
          this.alertReporter('Уучлаарай', response.message, response.status)
        }
      })
    },

    onPressCancelNew () {
      this.isNewVariant = false
      this.getVariants(this.backUpVariant.variant_id)
    },
    onPressNewVariant () {
      this.percent = ''
      this.difference = ''
      // this.isDiscount = false
      // this.isDiscountDate = false
      this.isNewVariant = true
      this.tempImages = []
      const tempOptions = []
      this.backUpVariant.option.forEach(element => {
        tempOptions.push({
          name: element.name,
          value: ''
        })
      })
      this.selectedVariant = {
        variant_get_sale_price: 0,
        start_date_sale: null,
        end_date_sale: null,
        created_at: '',
        variant_id: '',
        variant_barcode: '',
        variant_getPrice: 0,
        variant_sellPrice: 0,
        variant_sale_price: 0,
        variant_sort: '',
        diamond_code: '',
        product_id: '',
        variant_name: '',
        variant_sku: '',
        variant_status: 'active',
        variant_image: '',
        threshold_number: '',
        sell_check: false,
        option: tempOptions,
        warehouse: [{
          count: 0,
          warehouse_id: ''
        }]
      }
    },

    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },

    onClickWarehouse (data) {
      this.selectedWarehouse = data.warehouse_id
      this.warehouseList.forEach((element, index) => {
        if (element.warehouse_id === data.warehouse_id) {
          this.selectedIndex = index
        }
      })
    },

    generateWarehouse (currentData, variantId) {
      var tempWarehouseList = []
      this.isLoadWarehouse = true
      if (currentData && currentData.length !== 0) {
        const query = '?from=' + 0 + '&size=' + 999
        services.getWarehouses(query).then(response => {
          if (response && response.datas && response.datas.length !== 0) {
            response.datas.forEach((element) => {
              var tempWarehouse = null
              if (currentData && currentData.length !== 0) {
                currentData.forEach((secondElement) => {
                  if (element.warehouse_id === secondElement.warehouse_id) {
                    tempWarehouse = {
                      warehouse_id: secondElement.warehouse_id,
                      name_mon: element.name_mon,
                      name_eng: element.name_eng,
                      count: secondElement.count,
                      rack: secondElement.rack,
                      shelf: secondElement.shelf,
                      unit: secondElement.unit
                    }
                  }
                })
              }
              if (tempWarehouse === null) {
                tempWarehouse = {
                  warehouse_id: element.warehouse_id,
                  name_mon: element.name_mon,
                  name_eng: element.name_eng,
                  count: 0,
                  rack: '',
                  shelf: '',
                  unit: ''
                }
              }
              tempWarehouseList.push(tempWarehouse)
            })
          } else {
            tempWarehouseList = currentData
          }
        })
      } else {
        tempWarehouseList = currentData
      }
      this.getTransfers(tempWarehouseList, variantId)
    },

    defaultVariant (variant) {
      var newData = {
        variant_status: variant.variant_status ? variant.variant_status : 'inactive',
        variant_sort: variant.variant_sort ? variant.variant_sort : '',
        sell_check: variant.sell_check !== undefined ? variant.sell_check : false,
        variant_sellPrice: variant.variant_sellPrice ? variant.variant_sellPrice : 0,
        variant_sale_price: variant.variant_sale_price ? variant.variant_sale_price : 0,
        variant_getPrice: variant.variant_getPrice ? variant.variant_getPrice : 0,
        variant_barcode: variant.variant_barcode ? variant.variant_barcode : '',
        variant_endDate: variant.expired_date ? variant.expired_date : '',
        threshold_number: variant.threshold_number ? variant.threshold_number : 0,
        diamond_code: variant.diamond_code ? variant.diamond_code : '',
        variant_sku: variant.variant_sku,
        variant_image: variant.variant_image,
        variant_name: variant.variant_name,
        variant_id: variant.variant_id,
        product_id: variant.product_id,
        option: variant.option,
        variant_get_sale_price: variant.variant_get_sale_price,
        start_date_sale: variant.start_date_sale,
        end_date_sale: variant.end_date_sale,
        warehouse: variant.warehouse,
        avalabileCount: variant.avalabileCount
      }
      // this.currentWarehouse = variant.warehouse
      // this.generateWarehouse(variant.warehouse, variant.variant_id)
      this.setImages(variant.variant_image)
      return newData
    },

    getStatusOptions () {
      this.statusOption = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
    },

    onPressVariant (data) {
      this.isNewVariant = false
      const newVariant = this.defaultVariant(data)
      this.selectedVariant = newVariant
      this.backUpVariant = JSON.parse(JSON.stringify(newVariant))
      // eslint-disable-next-line no-mixed-operators
      // if (this.selectedVariant.start_date_sale !== undefined && this.selectedVariant.start_date_sale !== null || this.selectedVariant.end_date_sale !== undefined && this.selectedVariant.end_date_sale !== null) {
      //   this.isDiscountDate = true
      // } else {
      //   this.isDiscountDate = false
      // }
      // eslint-disable-next-line no-mixed-operators
      // if (this.selectedVariant.variant_get_sale_price !== undefined && this.selectedVariant.variant_get_sale_price !== null || this.selectedVariant.variant_sale_price !== undefined && this.selectedVariant.variant_sale_price !== null) {
      //   this.isDiscount = true
      // } else {
      //   this.isDiscount = false
      // }
    },

    onPressSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var tempImages = []
          var variant = JSON.parse(JSON.stringify(this.selectedVariant))
          variant.variant_getPrice = parseFloat(variant.variant_getPrice)
          variant.variant_sellPrice = parseFloat(variant.variant_sellPrice)
          variant.variant_sale_price = parseFloat(variant.variant_sale_price)
          variant.variant_sort = parseInt(variant.variant_sort)
          variant.warehouse.forEach(el => {
            el.count = parseInt(el.count)
          })
          this.tempImages.forEach((element) => {
            tempImages.push(element.url)
          })
          variant.variant_image = tempImages
          var body = {
            variant_id: this.selectedVariant.variant_id,
            variant: variant
          }
          this.isDisabled = true
          this.isLoading = true
          services.updateVariant(body).then((response) => {
            if (response.status === 'success') {
              this.isLoading = false
              this.isDisabled = false
              this.alertReporter('Амжилттай', response.message, response.status)
              this.confirmVariantDialog = false
            } else {
              this.alertReporter('Уучлаарай', response.message, response.status)
              this.isLoading = false
              this.isDisabled = false
              this.confirmVariantDialog = false
            }
          })
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    getVariants (variantId) {
      this.isLoading = true
      services.getProductByVariantId(variantId).then((response) => {
        this.isLoading = false
        if (response.status === 'success') {
          this.productData = response.data
          response.data.variants.forEach(element => {
            if (element.variant_id === variantId) {
              const newVariant = this.defaultVariant(element)
              this.selectedVariant = newVariant
              // eslint-disable-next-line no-mixed-operators
              // if (this.selectedVariant.start_date_sale !== undefined && this.selectedVariant.start_date_sale !== null || this.selectedVariant.end_date_sale !== undefined && this.selectedVariant.end_date_sale !== null) {
              //   this.isDiscountDate = true
              // } else {
              //   this.isDiscountDate = false
              // }
              // eslint-disable-next-line no-mixed-operators
              // if (this.selectedVariant.variant_get_sale_price !== undefined && this.selectedVariant.variant_get_sale_price !== null || this.selectedVariant.variant_sale_price !== undefined && this.selectedVariant.variant_sale_price !== null) {
              //   this.isDiscount = true
              // } else {
              //   this.isDiscount = false
              // }
              this.backUpVariant = JSON.parse(JSON.stringify(newVariant))
            }
          })
          this.getWarehousesBySupplierId(this.productData.supplier_id)
          this.getSuppliers()
        }
      })
    },

    getTransfers (tempData, variantId) {
      var tempWarehouseList = tempData
      services.getOneVariant(variantId).then((response) => {
        this.isLoadWarehouse = false
        if (response.status === 'success' && response.data) {
          tempWarehouseList.forEach((element) => {
            var tempWarehouse = null
            response.data.warehouse.forEach((secondElement) => {
              if (element.warehouse_id === secondElement.warehouse_id) {
                tempWarehouse = {
                  transferCount: secondElement.transferCount,
                  transfers: secondElement.transfers
                }
              }
            })
            if (!tempWarehouse) {
              tempWarehouse = {
                transferCount: 0,
                transfers: []
              }
            }
            Object.assign(element, tempWarehouse)
          })
          this.changesData = response.data.changes
        }
        this.warehouseList = tempWarehouseList
      })
    },

    setImages (images) {
      var tempImages = []
      if (images && Array.isArray(images)) {
        images.forEach(element => {
          tempImages.push({
            url: element
          })
        })
      } else {
        tempImages.push({
          url: images
        })
      }
      this.tempImages = tempImages
    },

    handleRemove (file, fileList) {
      var tempId = 0
      this.tempImages.forEach((element, index) => {
        if (element.uid === file.uid) {
          tempId = index
        }
      })
      this.tempImages.splice(tempId, 1)
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    getFile (file) {
      handlers.checkImageSize(file, true, 1020, 1020).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              this.tempImages.push({
                url: response.url
              })
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file)
        this.$message.error(error.message)
      })
    },
    getSuppliers () {
      const query = ''
      services.getSuppliers(query).then(data => {
        this.suppliers = data.datas
        this.suppliers.forEach(element => {
          if (element.supplier_id === this.productData.supplier_id) {
            this.taxPaid = element.is_tax_paid
          }
          if (this.taxPaid !== true) {
            const a = (((this.selectedVariant.variant_sellPrice / 1.1) - (this.selectedVariant.variant_getPrice)) * 100) / (this.selectedVariant.variant_sellPrice / 1.1)
            this.percent = a
            this.percent = parseFloat(a).toFixed(2)
          } else {
            const b = (((this.selectedVariant.variant_sellPrice / 1.1) - (this.selectedVariant.variant_getPrice / 1.1)) * 100) / (this.selectedVariant.variant_sellPrice / 1.1)
            this.percent = b
            this.percent = parseFloat(b).toFixed(2)
          }
          if (this.selectedVariant.variant_sellPrice === 0 || this.selectedVariant.variant_getPrice === 0) {
            this.percent = 0
          }
        })
        this.difference = (this.selectedVariant.variant_sellPrice - this.selectedVariant.variant_getPrice)
      })
    },

    isDisabledButton (type, warehouseListIndex) {
      if (type === 'barCode') {
        let isDisabled = true
        if (this.selectedVariant.variant_barcode !== '' || this.selectedVariant.variant_endDate !== '') {
          isDisabled = false
        } else {
          isDisabled = true
        }
        return isDisabled
      } else if (type === 'position') {
        let isDisabled = true
        if (warehouseListIndex.rack !== '' || warehouseListIndex.unit !== '' || warehouseListIndex.shelf !== '') {
          isDisabled = false
        } else {
          isDisabled = true
        }
        return isDisabled
      }
    },

    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    }
  }
}
</script>
